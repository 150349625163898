exports.ROLEOPTIONS = [
    {
        label: 'Administrator',
        value: 'Administrator'
    },
    {
        label: "Manager",
        value: "Manager"
    },
    {
        label: 'User',
        value: "User"
    }
]

exports.CATEGORYOPTIONS = [
    {
        label: 'Hours',
        value: 'Hours'
    },
    {
        label: "Kilometers",
        value: "Kilometers"
    },
    {
        label: 'Costs',
        value: "Costs"
    },
    {
        label: 'Travel Hours',
        value: 'Travel Hours'
    },
    {
        label: 'Planned',
        value: 'Planned'
    }
]