import React from 'react'
import UserList from '../../features/users/UserList'

const Users = () => {
  return (
    
    <UserList />
  )
}

export default Users